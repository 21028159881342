<template>
  <span class="badge">{{ badgeText }}</span>
</template>

<script>
export default {
  name: 'badge',
  props: {
    badgeText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/base";

.badge {
  padding: 0.3rem 0.3rem 0.3rem 0.4rem;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  border-radius: 1rem;
  color: $white;
  background-color: $blue-old-banner-home;
}
</style>
