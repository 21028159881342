import i18next from '../i18next'

export const getPageTitleForRouteName = async (routeName, interpolationData = {}) => {
  const key = `pageTitles.${routeName}`

  await i18next.loadNamespaces('pageTitles')

  const doesKeyExist = i18next.exists(`pageTitles:${key}`)

  return doesKeyExist ? i18next.t(`pageTitles:pageTitles.${routeName}`, interpolationData) : i18next.t('pageTitles:pageTitles.missingKey')
}
