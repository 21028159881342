<template>
  <div id="app">
    <skip-link />
    <transition name="fade">
      <ribbon
        v-if="alert.message"
        id="app-ribbon" ref="ribbon"
        :variant="alert.level"
        :show="alert.showSeconds || true"
        dismissible
        @dismissed="$emit('dismiss')"
      >
        <span v-html="alert.message" />
        <div class="alert__actions">
          <b-button id="alertButton" :variant="action.level || 'outline-light'" size="sm" v-for="(action, index) in alert.actions" :key="`app-alert-${index}`" @click="action.callback">{{ action.text }}</b-button>
        </div>
      </ribbon>
    </transition>
    <maintenance-popup id="app-maintenance" v-if="maintenanceMode" />
    <div class="app__alertContainer">
      <dismissable-alert
        v-if="showSsoAlert && !prefetching"
        class="app__dismissableAlert"
        aria-live="polite"
        role="status"
      >
        <span v-html="$tt('account.signedIn')" />
      </dismissable-alert>
    </div>
    <component :is="layout" :showAlerts="!prefetching" :hideFooter="hideFooter">
      <router-view
        :class="{
          'app__page': true,
          'app__page--prefetching': prefetching
        }"
        :key="routeKey"
      />
      <loading-spinner class="app__spinner" :loading="prefetching" />
    </component>
    <organization-changed />
    <feature-announcement
      :title="$tt('popupBlockedWarning.title')"
      :body="$tt('popupBlockedWarning.body')"
      :buttonText="$tt('popupBlockedWarning.buttonText')"
      feature="blockedPopupWarning"
    />
  </div>
</template>

<script>
import SkipLink from '../components/a11y/SkipLink'
import DismissableAlert from '../components/content/DismissableAlert.vue'
import LoadingSpinner from '../components/forms/LoadingSpinner'
import MaintenancePopup from '../components/maintenance/MaintenancePopup'
import FeatureAnnouncement from '../components/modal/FeatureAnnouncement.vue'
import Ribbon from '../components/ribbon/Ribbon.vue'
import AppLayoutDefault from '../hocs/AppLayoutDefault'
import AppLayoutNoScroll from '../hocs/AppLayoutNoScroll'
import AppLayoutOpen from '../hocs/AppLayoutOpen'
import OrganizationChanged from '../hocs/OrganizationChanged'
import messages from '../translations/groups'
import { appLayouts } from './AppConstants'
import AppLayoutMinimal from './AppLayoutMinimal.vue'
/**
 * @module App
 * Displays a shell and routes
 */
export default {
  name: 'app',
  i18n: {
    fallbackLocale: 'en-US',
    messages
  },
  components: {
    AppLayoutDefault,
    AppLayoutOpen,
    AppLayoutNoScroll,
    AppLayoutMinimal,
    Ribbon,
    MaintenancePopup,
    DismissableAlert,
    LoadingSpinner,
    OrganizationChanged,
    FeatureAnnouncement,
    SkipLink
  },
  data: () => ({
    loading: true
  }),
  props: {
    prefetching: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Object,
      default: () => ({})
    },
    maintenanceMode: {
      type: Boolean,
      default: false
    },
    showSsoAlert: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    layout () {
      return this.$route.meta.layout || appLayouts.DEFAULT
    },
    hideFooter () {
      return this.$route.meta.hideFooter
    },
    routeKey () {
      return this.$route.name === 'detail'
        ? this.$route.params.module + (this.$route.params.style ? `/${this.$route.params.style}` : '')
        : this.$route.name
    }
  },
  methods: {
    scrollBehavior (e) {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss">
@import '../styles/base';

body,
html {
  width: 100%;
  height: 100%;

  &.no-scroll {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

#app {
  // This is under #app instead of .app due
  // to needing higher specifity for overriding
  // the default position of the spinner component.
  .app__spinner {
    position: fixed;
  }
}

#app,
#app-main {
  width: 100%;
  height: 100%;
}

#app-ribbon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-fixed + 1000; // We want this to always be on top

  .alert__actions {
    display: inline-block;
  }

  .alert__actions .btn:not(:last-child) {
    margin-right: calc($spacer / 2);
  }
}

#app-maintenance {
  position: fixed;
  z-index: $zindex-fixed + 1000;
  bottom: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  display: flex;

  @include media-breakpoint-up('md') {
    max-width: 26rem;
    bottom: 2rem;
    left: 2rem;
  }
}

.app {
  &__spinner {
    position: fixed;
  }

  &__alertContainer {
    width: 100%;
    position: absolute;
    z-index: $zindex-fixed + 1; // We want this to always be on top except for loading spinner
    top: 4.375rem + 2.5rem; // navbar + breadcrumbs

    @include media-breakpoint-up('md') {
      top: 5rem + 2.5rem; // navbar + breadcrumbs
    }
  }

  &__dismissableAlert {
    margin: 0 1rem;

    @include media-breakpoint-up('md') {
      margin: 0 1.5rem;
    }

    @include media-breakpoint-up('lg') {
      margin: 0 auto;
      max-width: 48.75rem; // matches max width of homepage hero - padding
    }
  }

  &__page {
    transition: opacity 0.25s ease-in-out;

    &--prefetching {
      opacity: 0;
    }
  }
}
</style>
