import { OrganizationService } from '../../services/organization'
import pendo from '../../util/integrations/pendo'

/**
 * @module Organization Module
 * @description Vuex module responsible for fetching organization data
 */

const state = {
  pending: false,
  organizations: [],
  locations: [],
  departments: [],
  status: 0,
  error: ''
}

const getters = {
  currentOrganization: state => state.organizations.length === 1 ? state.organizations[0] : null,
  organizationName: state => state.organizations.length === 1 ? state.organizations[0].organizationName : ''
}

const actions = {
  async organizations ({ commit }) {
    commit('request')
    try {
      const organizations = await OrganizationService.fetchOrganizations()
      commit('fetchOrganizationsSuccess', organizations)
    } catch (e) {
      commit('organizationsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  },

  async organizationSearch ({ commit }, input) {
    commit('request')
    try {
      const organizations = await OrganizationService.searchOrganizations(input)
      commit('fetchOrganizationsSuccess', organizations)
    } catch (e) {
      commit('organizationsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  },

  async details ({ commit, state, getters }, request) {
    if (state.status === 200 && (!request || !request.force)) {
      return getters.organizationName
    }

    commit('request')
    try {
      let response = {}
      if (request?.organizationId && request?.accessCode) {
        response = await OrganizationService.fetchDetails(request)
      } else {
        response = await OrganizationService.fetchDetailsJwt(request)
      }

      commit('detailsSuccess', response)
      pendo.updateOrganization()
      return response.organization.organizationName
    } catch (e) {
      commit('detailsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  },

  async addDepartment ({ commit }, request) {
    commit('request')
    try {
      let department

      if (request.organizationId && request.accessCode) {
        department = await OrganizationService.createDepartment(request.organizationId, request.accessCode, request.departmentName)
      } else {
        department = await OrganizationService.createDepartmentJwt(request.departmentName)
      }

      commit('addDepartmentSuccess', department)
      return department
    } catch (e) {
      commit('organizationsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  }
}

const mutations = {
  request (state) {
    state.pending = true
    state.status = 0
    state.error = ''
  },
  fetchOrganizationsSuccess (state, organizations) {
    state.pending = false
    state.status = 200
    state.error = ''
    state.organizations = organizations
  },
  detailsSuccess (state, { locations, departments, organization }) {
    state.pending = false
    state.status = 200
    state.error = ''
    state.locations = locations
    state.departments = departments
    state.organizations = [organization]
  },
  addDepartmentSuccess (state, department) {
    state.pending = false
    state.status = 200
    state.error = ''
    state.departments.push(department)
  },
  organizationsError (state, { errorMessage, errorCode }) {
    state.pending = false
    state.status = errorCode
    state.error = errorMessage
    state.organizations = []
  },
  detailsError (state, { errorMessage, errorCode }) {
    state.pending = false
    state.status = errorCode
    state.error = errorMessage
    state.locations = []
    state.departments = []
  }
}

export const organization = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
