import LogRocket from 'logrocket'
import StorageService from '../../services/storage'
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import store from '../../store'

const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup([
  'password',
  'token'
])

const logRocket = {
  initialize: () => LogRocket.init(
    window.env.PUBLIC_LOGROCKET,
    { network: { requestSanitizer, responseSanitizer } }
  ),

  identify: () => {
    try {
      if (store.getters['account/accountID']) {
        LogRocket.identify(store.getters['account/accountID'].toString())

        StorageService.setLogRocketInfo({ identified: true })
      }
    } catch {}
  },

  resetIdentity: () => {
    logRocket && LogRocket.identify()
  }
}

export default logRocket
