import { jwtDecode } from 'jwt-decode'
import UserService from '../user'
import StorageService from '../storage'
import router from '../../router'
import store from '../../store'

const TOKEN_VALIDITY_BUFFER_S = 10
let ACTIVE_REFRESH_CALL
let LOGGING_OUT

const isTokenValid = (token) => {
  try {
    const decodedToken = jwtDecode(token)
    const currentUTCTimeInSeconds = Math.round(new Date().getTime() / 1000) + TOKEN_VALIDITY_BUFFER_S
    return (currentUTCTimeInSeconds < decodedToken.exp)
  } catch {
    return false
  }
}

export default async function requestRefreshInterceptor (request) {
  if (request.skipAuthRefresh) return request

  const tokens = StorageService.getTokenSet()

  if (tokens) {
    const isAccessTokenValid = isTokenValid(tokens.access_token)
    const isRefreshTokenValid = isTokenValid(tokens.refresh_token)

    if (!LOGGING_OUT && !isAccessTokenValid && !isRefreshTokenValid) {
      LOGGING_OUT = true
      await store.dispatch('account/logout')
      window.location.replace(router.resolve({ name: 'login' }).href)
      LOGGING_OUT = false
    }

    if (!isAccessTokenValid && isRefreshTokenValid) {
      if (!ACTIVE_REFRESH_CALL) {
        ACTIVE_REFRESH_CALL = new Promise((resolve, reject) => {
          UserService.refresh()
            .then((accessToken) => resolve(accessToken))
            .catch((error) => reject(error))
        })
      }

      return ACTIVE_REFRESH_CALL
        .then((accessToken) => {
          const updatedRequest = {
            ...request,
            headers: {
              common: {
                Authorization: `Bearer ${accessToken}`
              }
            },
            skipAuthRefresh: true
          }

          return updatedRequest
        })
        .catch(async () => {
          LOGGING_OUT = true
          await store.dispatch('account/logout')
          window.location.replace(router.resolve({ name: 'login' }).href)
          LOGGING_OUT = false
        })
        .finally(() => { ACTIVE_REFRESH_CALL = undefined })
    }
  }

  return request
}
