import Vue from 'vue'
import Vuex from 'vuex'
import { cloneDeep, forOwn } from 'lodash'
import { account } from './account'
import { app } from './app'
import { assessment } from './assessment'
import { location } from './location'
import { organization } from './organization'
import { text } from './text'
import { people } from './people'
import { groups } from './groups'
import { featureFlags } from './featureFlags'
import { demographics } from './demographics'
import { newAssessment } from './newAssessment'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const demoGroupsData = new VuexPersist({
  key: 'demo-groups-data',
  reducer: (state) => ({
    groups: state.groups
  })
})

export const storeModules = {
  account,
  app,
  assessment,
  location,
  organization,
  text,
  people,
  groups,
  featureFlags,
  demographics,
  newAssessment
}

const store = new Vuex.Store({
  strict: true,
  modules: cloneDeep(storeModules),
  mutations: {
    resetStore (state) {
      forOwn(storeModules, (value, key) => {
        if (key === 'featureFlags') return
        state[key] = cloneDeep(value.state)
      })
    }
  },
  plugins: [demoGroupsData.plugin]
})

export default store
