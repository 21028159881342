<template>
  <div class="app__header">
    <b-navbar id="nav-main" class="justify-content-between">
      <!-- Left aligned nav items -->
      <b-navbar-nav class="align-items-center" tag="div" align="left">
        <b-navbar-brand
          v-if="isFacilitator && $route.name && $route.name.includes($tt('ui.facilitator').toLowerCase())"
          id="logo-catalyst-facilitator-link_home"
          class="nav-main__logo"
          @click="closeNavMenu()"
          :to="{ name: 'facilitator' }"
        >
          <logo-catalyst-facilitator />
        </b-navbar-brand>
        <b-navbar-brand
          v-else
          id="logo-brand-mark-link_home"
          class="nav-main__logo"
          @click="closeNavMenu()"
          :to="{ name: 'home' }"
        >
          <logo-brand-mark variant="light" />
        </b-navbar-brand>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="align-items-center" tag="div" align="right">
        <!-- Integrated Facilitation -->
        <div v-if="goToIntegratedFacilitationFeatureFlag" class="nav-main__admin">
          <!-- Create null link -->
          <router-link class="nav-main__dashboard-link" :to="{ name: 'facilitatorSession' }">
            <span class="nav-main__name" v-html="$tt('account.createClass')"></span>
          </router-link>
        </div>
        <!-- Partner Dashboard -->
        <div v-if="goToAccountDashboardFeatureFlag && hasDashboardAccess" class="nav-main__admin">
          <router-link class="nav-main__dashboard-link" :to="{ name: 'adminAuthenticate', query: { redirect: '/dashboard'}}">
            <span class="nav-main__name" v-html="$tt('account.goToDashboard')"></span>
            <img src="/images/dashboard_icon.svg" :alt="$tt('account.goToDashboard')" />
          </router-link>
        </div>
        <!-- Go To Admin (Salt) -->
        <div v-if="goToAdminButtonFeatureFlag && isOrganizationAdminAccount" class="nav-main__admin">
          <router-link class="nav-main__go-to-admin-link" :to="{ name: 'adminAuthenticate'}">
            <span class="nav-main__name" v-html="$tt('account.goToAdmin')"></span>
            <img src="/images/admin-arrow.svg" class="nav-main__admin-icon" alt="" />
          </router-link>
        </div>
        <!-- User Avatar -->
        <div
          class="d-none d-md-flex"
          v-if="userName && userAvatar"
        >
          <router-link class="nav-main__greeting nav-main__greeting-link" to="/settings" @click.native="closeNavMenu()">
            <span class="nav-main__name">{{ $tt('account.greeting', { username: unescape(userName) }) }}</span>
            <img
              class="nav-main__avatar"
              :src="userAvatar"
              :alt="$tt('account.profilePicture', { username: unescape(userName) })"
            />
            <img
              v-if="isOrganizationAdminAccount"
              class="nav-main__admin-badge"
              src="/images/avatar/admin_badge.svg"
              width="18px"
              height="22px"
              :alt="$tt('account.adminBadge')"
            />
          </router-link>
        </div>

        <div class="d-none d-md-block">
          <router-link
            class="nav-main__logout"
            to="/logout"
            @click.native="closeNavMenu()"
          >{{ $tt('account.signout') }}</router-link>
        </div>

        <div>
          <button
            aria-label="nav-menu"
            :class="value ? null : 'collapsed'"
            :aria-expanded="value ? 'true' : 'false'"
            aria-controls="nav-main-pathways"
            @click="$emit('input', !value)"
            class="nav-main__toggler navbar-toggler nav-icon"
          >
            <div class="navbar-toggler__icon" />
          </button>
        </div>
      </b-navbar-nav>
    </b-navbar>

    <!-- eslint-disable-next-line vue/no-mutating-props -->
    <b-collapse v-model="value" ref="collapse" id="nav-main-pathways" class="nav-main__pathways">
      <nav-menu
        :items="items"
        :isMenuOpen="value"
        :isDemoUser="isDemoUser"
        :isFacilitator="isFacilitator"
        :isOrganizationAdminAccount="isOrganizationAdminAccount"
        :isLearnerArchived="isLearnerArchived"
        @closeNavMenu="closeNavMenu()" />
    </b-collapse>
  </div>
</template>

<script>
import { unescape } from 'lodash'
import LogoBrandMark from '../components/logo/LogoBrandMark'
import NavMenu from '../components/nav/NavMenu'

export default {
  name: 'app-header',
  components: {
    LogoBrandMark,
    NavMenu
  },
  props: {
    userName: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },

    /**
     * array of objects that the nav menu will display
     */
    items: {
      type: Object,
      default: () => ({})
    },

    /**
     * Boolean we use to open and close main menu
     */
    value: {
      type: Boolean,
      default: false
    },

    /**
     * Hides settings info for the demo experience
     * Mostly won't be true but defaulting to that for safety
     */
    isDemoUser: {
      type: Boolean,
      default: true
    },

    /**
     * Boolean we use to change header logo
     * if user viewing as Facilitator role
     */
    isFacilitator: {
      type: Boolean,
      default: false
    },

    isOrganizationAdminAccount: {
      type: Boolean,
      default: false
    },

    /**
     * Boolean we use to check
     * if learner is archived
     */
    isLearnerArchived: {
      type: Boolean,
      default: false
    },

    /**
     * Feature Flag value to hide/show
     * Go to Admin button
     */
    goToAdminButtonFeatureFlag: {
      type: Boolean,
      default: false
    },

    /**
     * Feature Flag value to hide/show
     * Button to Partner Dashboard
     */
    goToAccountDashboardFeatureFlag: {
      type: Boolean,
      default: false
    },
    hasDashboardAccess: {
      type: Boolean,
      default: true
    },

    /**
     * Feature Flag value to hide/show
     * Button to Integrated Facilitation
     */
    goToIntegratedFacilitationFeatureFlag: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userAvatar () {
      return this.avatar === '/images/avatar/avatar.svg'
        ? '/images/avatar/avatar_light.svg'
        : this.avatar
    }
  },
  methods: {
    unescape,
    closeNavMenu () {
      return this.$emit('closeNavMenu')
    }
  }
}
</script>
<style lang="scss">
@import "../styles/base";
@import "../styles/components/scaffold";

#nav-main.navbar {
  flex-wrap: wrap;
  position: relative;
  z-index: 2;

  & .nav-main__toggler,
  & .nav-main__profile {
    flex-grow: 0;
  }

  & .nav-main__toggler:focus-visible {
    @extend %accessible-outline;
    outline-offset: -5px;
  }

  & .nav-main__logo {
    display: flex;
    flex-grow: 1;
  }

  .navbar-brand {
    margin-left: space(2);

    @include media-breakpoint-down("sm") {
      margin-left: 1rem;
    }

    @include media-breakpoint-up("lg") {
      margin-left: 2rem;
    }
  }
}

.app__header {
  background-color: $primary;

  .navbar {
    padding: 0;
    align-items: center;
  }

  .navbar-expand .navbar-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 5rem;
    padding: 0;
    background-color: $gray-100;
    cursor: pointer;
    border: none;
    border-radius: 0;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    @include media-breakpoint-down('sm') {
      height: 4.375rem;
      width: 4.375rem;
    }
  }
}

.navbar-toggler__icon,
.navbar-toggler__icon::before,
.navbar-toggler__icon::after {
  display: block;
  position: absolute;
  height: 3px;
  width: space(3);
  background: $primary;
  border-radius: 5px;
  opacity: 1;
  transition: 0.25s ease-in-out;
  transform-origin: 50% 50%;
}

.navbar-toggler__icon {
  transform: translate3d(0, 0, 0);

  .navbar-toggler:not(.collapsed) & {
    transform: translate3d(60px, 0, 0);
    background-color: rgba($color: $primary, $alpha: 0);
  }

  &::before {
    content: " ";
    transform: translate3d(0, -9px, 0);

    .navbar-toggler:not(.collapsed) & {
      transform: translate3d(-60px, 0, 0) rotate(135deg);
    }
  }

  &::after {
    content: " ";
    transform: translate3d(0, 9px, 0);

    .navbar-toggler:not(.collapsed) & {
      transform: translate3d(-60px, 0, 0) rotate(-135deg);
    }
  }
}

.nav-main__admin {
  display: flex;
  align-items: center;
  line-height: 1.061rem;
  border-right: 1px solid rgba($color: $white, $alpha: 0.18);
  padding-right: space(1);
  font-size: 0.75rem;

  a {
    display: flex;
    color: $white;
    &:hover {
      color: $white;
    }
  }

  @include media-breakpoint-up("sm") {
    font-size: 0.875rem;
  }

  @include media-breakpoint-up("md") {
    font-size: 1rem;
  }
}

.nav-main__admin-icon {
  margin-left: 10px;

  @include media-breakpoint-down("sm") {
    margin-left: 5px;
  }
}

.nav-main__dashboard-link {
  display: flex;
  align-items: center;
  padding: 7px;

  .nav-main__name {
    margin-right: 7px;
    font-size: 0.75rem;

    @include media-breakpoint-up("sm") {
      font-size: 0.875rem;
    }

    @include media-breakpoint-up("md") {
      font-size: 1rem;
    }
  }
}

.nav-main__go-to-admin-link {
  margin-left: 15px;

  @include media-breakpoint-down("sm") {
    margin-left: 10px;
  }
}

.nav-main__greeting {
  display: flex;
  align-items: center;
  padding-right: space(2);
  padding-left: space(1);
  border-right: 1px solid rgba($color: $white, $alpha: 0.18);

  .nav-main__avatar {
    height: 2.25rem;
    width: 2.25rem;
    margin-left: 0.75rem;
    position: relative;
    border: 2px solid $white;
    border-radius: 50%;
    background-color: $white;
    box-sizing: content-box;
  }

  .nav-main__admin-badge {
    position: absolute;
    bottom: -5px;
    right: 14px;
  }
}

.nav-main__logout {
  margin-left: space(2);
  margin-right: space(2);
  cursor: pointer;
}

.nav-main__greeting,
.nav-main__logout,
.nav-main__admin {
  color: $white;

  &:hover {
    color: $white;
  }
}

.nav-main__greeting-link {
  cursor: pointer;
  position: relative;
}
</style>
