import HealthService from '../../services/health'
import { stateMerge } from '../../util/stateMerge'

export const defaultAlert = {
  message: null,
  level: null,
  actions: [],
  showSeconds: 0
}

export const state = {
  prefetchError: '',
  prefetching: [],
  alert: {
    ...defaultAlert
  },
  maintenanceMode: false,
  maintenanceFlagError: null,
  maintenanceFlagStatusCode: 0,
  healthError: null,
  healthStatusCode: 0,
  healthPending: false
}

export const getters = {
  isPrefetching: state => Boolean(state.prefetching.length)
}

export const actions = {
  async refreshMaintenanceMode ({ commit }) {
    try {
      commit('maintenanceFlagRequest')
      try {
        const maintenanceFlag = await HealthService.fetchMaintenanceFlag()

        if (maintenanceFlag) {
          commit('setMaintenanceMode', maintenanceFlag)
          commit('maintenanceFlagSuccess')
          return
        }

        commit('maintenanceFlagSuccess')
      } catch (e) {
        commit('maintenanceFlagError', {
          errorMessage: e.message,
          errorCode: e.errorCode
        })
      }

      commit('healthRequest')
      await HealthService.checkRootbeerDependencies()

      commit('setMaintenanceMode', false)
      commit('healthSuccess')
    } catch (e) {
      commit('setMaintenanceMode', true)
      commit('healthError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  }
}

export const mutations = {
  alert (state, alert) {
    stateMerge(state.alert, alert)
  },
  clearAlert (state) {
    stateMerge(state.alert, defaultAlert)
  },
  prefetchStart (state, hash) {
    // Some pages might trigger a prefetchStart
    // multiple times depending on internal redirects.
    // We're only interested in one of them because
    // when we call prefetchEnd, we only call once.

    if (!state.prefetching.includes(hash)) {
      state.prefetching.push(hash)
    }
  },
  prefetchEnd (state, hash) {
    state.prefetching = state.prefetching.filter((p) => p !== hash)
  },
  prefetchError (state, error) {
    state.prefetching = []
    state.prefetchError = error.message
  },
  setMaintenanceMode (state, maintenanceFlag) {
    state.maintenanceMode = maintenanceFlag
  },
  healthRequest (state) {
    state.healthError = null
    state.healthStatusCode = 0
    state.healthPending = true
  },
  healthSuccess (state) {
    state.healthPending = false
  },
  healthError (state, { errorMessage, errorCode }) {
    state.healthError = errorMessage
    state.healthStatusCode = errorCode
    state.healthPending = false
  },
  maintenanceFlagRequest (state) {
    state.maintenanceFlagError = null
    state.maintenanceFlagStatusCode = 0
    state.healthPending = true
  },
  maintenanceFlagSuccess (state) {
    state.healthPending = false
  },
  maintenanceFlagError (state, { errorMessage, errorCode }) {
    state.maintenanceFlagError = errorMessage
    state.maintenanceFlagStatusCode = errorCode
    state.healthPending = false
  }
}

export const app = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
