import ApiService from '../api'
import { ExtendableError } from '../../util/error'

class OrganizationError extends ExtendableError {
  constructor (errorCode, message) {
    super(message)
    this.name = 'OrganizationError'
    this.message = message
    this.errorCode = errorCode
  }
}

const OrganizationService = {

  /**
   * Fetch a list of all organizations
   * @throws OrganizationError
   */
  async fetchOrganizations () {
    try {
      const response = await ApiService.customRequest({
        method: 'GET',
        url: '/organizations'
      })
      return response.data
    } catch (error) {
      throw new OrganizationError(error.response.status, error.response.data.message)
    }
  },

  /**
   * Fetches all organizations whose name contains the given input string
   * @param { string } input - search term
   * @throws OrganizationError
   */
  async searchOrganizations (input) {
    try {
      const response = await ApiService.customRequest({
        method: 'POST',
        url: '/organizations/search',
        data: { input }
      })
      return response.data
    } catch (error) {
      throw new OrganizationError(error.response.status, error.response.data.message)
    }
  },

  async fetchDetails ({ organizationId, accessCode, include = [] }) {
    try {
      const requestData = {
        method: 'GET',
        url: `/organizations/details/${organizationId}/${accessCode}`,
        params: {
          ...(include && include.length) && { include: include.join() }
        }
      }
      const response = await ApiService.customRequest(requestData, '/v2')
      return response.data
    } catch (error) {
      throw new OrganizationError(error.response.status, error.response.data.message)
    }
  },

  async fetchDetailsJwt ({ include = [] } = {}) {
    try {
      const requestData = {
        method: 'GET',
        url: '/organizations/details',
        params: {
          ...(include && include.length) && { include: include.join() }
        }
      }
      const response = await ApiService.customRequest(requestData, '/v2')
      return response.data
    } catch (error) {
      throw new OrganizationError(error.response.status, error.response.data.message)
    }
  },

  async fetchFacilitatorDetails () {
    try {
      const requestData = {
        method: 'GET',
        url: '/facilitator/organizations/detail'
      }
      const response = await ApiService.customRequest(requestData)
      return response.data
    } catch (error) {
      throw new OrganizationError(error.response.status, error.response.data.message)
    }
  },

  async createDepartment (organizationId, accessCode, departmentName) {
    try {
      const requestData = {
        method: 'POST',
        url: `/organizations/department/${organizationId}/${accessCode}`,
        data: {
          DepartmentName: departmentName
        },
        config: {
          headers: { 'Content-Type': 'application/json' }
        }
      }
      const response = await ApiService.customRequest(requestData, '/v1')
      return response.data
    } catch (error) {
      throw new OrganizationError(error.response.status, error.response.data.message)
    }
  },

  async createDepartmentJwt (departmentName) {
    try {
      const requestData = {
        method: 'POST',
        url: '/organizations/department',
        data: {
          DepartmentName: departmentName
        },
        config: {
          headers: { 'Content-Type': 'application/json' }
        }
      }
      const response = await ApiService.customRequest(requestData, '/v1')
      return response.data
    } catch (error) {
      throw new OrganizationError(error.response.status, error.response.data.message)
    }
  }
}

export default OrganizationService
export { OrganizationService, OrganizationError }
