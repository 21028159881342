export default {
  ui: {
    hi: 'Hi',
    a: 'a | an',
    ok: 'Ok',
    orEnter: 'or press Enter',
    details: 'details',
    yes: 'Yes',
    no: 'No',
    on: 'On',
    off: 'Off',
    name: 'Name',
    save: 'Save',
    cancel: 'Cancel',
    clear: 'Clear',
    change: 'Change',
    learnMore: 'Learn more',
    open: 'open',
    error: 'error | errors',
    field: 'field | fields',
    submit: 'Submit',
    continue: 'Continue',
    assessment: 'Assessment',
    introduction: 'Intro',
    survey: 'Survey',
    complete: 'Complete',
    people: 'People',
    person: 'Person',
    privacy: 'Privacy',
    a11y: 'Accessibility',
    terms: 'Terms of use',
    cookies: 'Cookies',
    manageCookies: 'Manage cookies',
    feedback: 'Feedback',
    forgot: 'Forgot',
    back: 'Go back',
    next: 'Next',
    previous: 'Previous',
    new: 'new',
    navigateTo: 'Navigate to {title}',
    pageCount: 'Page {current} of {total}',
    breadcrumbs: 'breadcrumbs',
    closeWindow: 'Close this window',
    incomplete: 'User has not completed assessment',
    privacyMessage: `Note: {0} has chosen not to share their DiSC information with
    colleagues. Be thoughtful how you share their information | Note: {0} and {1} have chosen not to share their DiSC information with
    colleagues. Be thoughtful how you share their information `,
    spyNav: {
      jumpTo: 'Jump to',
      spyAriaLabel: 'Quick Links'
    },
    copyright: '© by John Wiley & Sons, Inc.',
    reserved: 'All rights reserved.',
    graphicsUsed: 'Graphics used under license by',
    graphicsUsedList: 'tiny_selena/shutterstock.com, GoodStudio/shutterstock.com.',
    facilitator: 'FACILITATOR',
    footerEmailTitle: 'Email',
    footerTechSupportHoursTitle: 'Hours',
    footerPhoneTitle: 'Phone',
    techSupportHours: 'Daily, 24 hours',
    region: {
      americas: {
        name: 'Americas',
        number: '+1 763-762-9025'
      },
      europe: {
        name: 'EMEA',
        number: '+44 186 552 1556'
      },
      asia: {
        name: 'Asia / Australia',
        number: '+61 7 3703 1694'
      }
    },
    needHelp: 'Tech support',
    needHelpEmail: 'catalystsupport@wiley.com',
    otherQuestions: 'Other questions?',
    moreInformation: 'More information',
    disclaimer:
      'John Wiley & Sons, Inc or its associated companies, are the authorized users or owners of DiSC and Everything DiSC and associated trademarks on this site.',
    download: 'Download',
    downloadTranscript: 'Download transcript',
    sort: 'Sort',
    style: 'style',
    styles: 'styles',
    department: 'department',
    departments: 'departments',
    group: 'group',
    search: 'Search',
    filter: 'Filter',
    resetFilters: 'Reset all filters',
    you: 'you',
    them: 'them',
    settings: 'Settings',
    getAdvice: 'Get advice',
    accountSettings: 'Account settings',
    catalystAdmin: 'Catalyst™ Admin',
    downloadReports: 'Download reports',
    seeAsFacilitator: 'Facilitator',
    home: 'Home',
    intro: 'Intro',
    browserWarning: {
      title: 'You\'re using an unsupported browser!',
      modalBody: 'Switch to one of the supported browsers below for the best possible Catalyst experience. If you continue with Internet Explorer, you may experience problems with some elements of the site. {0}',
      confirmIE: ' Continue using Internet Explorer.',
      supportedBrowsers: 'Supported browsers',
      browsers: '<B>Google Chrome</B><br><a target="_blank" href="https://www.google.com/chrome/">Go to download</a><br><B>Microsoft Edge</B><br><a target="_blank" href="https://www.microsoft.com/en-us/edge">Go to download</a><br> <B>Safari</B><br><a target="_blank" href="https://support.apple.com/en-us/HT204416">Go to download</a>'
    },
    seeMore: 'See more',
    seeLess: 'See less',
    processing: 'processing',
    avatarAlt: 'avatar of {learner}',
    theStyle: 'the {style} style',
    or: 'or',
    show: 'Show',
    hide: 'Hide',
    videoPlayer: 'Video player',
    warning: 'Warning'
  },
  cookies: {
    consent:
      'We use cookies to offer you a better site experience, analyze site usage, and optimize functionality and performance. By continuing to use this website, you consent to the use of functional cookies in accordance with our ',
    understand: 'I understand',
    url: 'Cookie Policy'
  },
  navigator: {
    invalid:
      'Warning: DiSC<sup>®</sup> assessment sections, as in life, only go forward.'
  },
  accessCode: {
    title: 'Continue sign up',
    name: 'Access code',
    placeholder: 'Enter your access code',
    submit: '@:ui.continue',
    label: 'We need your access code. You can find it in your @:brand.name on @:brand.title invitation email.',
    error: 'Sorry, we don’t recognize that access code. Please try again.'
  },
  brand: {
    name: 'Everything DiSC<sup>&reg;</sup>',
    title: 'Catalyst&trade;',
    tagline: 'Reimagine your<br/>world with DiSC<sup>®</sup>',
    taglineNoBreak: 'Reimagine your world with DiSC<sup>&reg</sup>',
    styles: {
      DC: {
        name: 'DC',
        priority: 'Challenge'
      },
      D: {
        name: 'D',
        priority: 'Results',
        label: 'Dominance'
      },
      Di: {
        name: 'Di',
        priority: 'Action'
      },
      iD: {
        name: 'iD',
        priority: 'Action'
      },
      i: {
        name: 'i',
        priority: 'Enthusiasm',
        label: 'Influence'
      },
      iS: {
        name: 'iS',
        priority: 'Collaboration'
      },
      Si: {
        name: 'Si',
        priority: 'Collaboration'
      },
      S: {
        name: 'S',
        priority: 'Support',
        label: 'Steadiness'
      },
      SC: {
        name: 'SC',
        priority: 'Stability'
      },
      CS: {
        name: 'CS',
        priority: 'Stability'
      },
      C: {
        name: 'C',
        priority: 'Accuracy',
        label: 'Conscientiousness'
      },
      CD: {
        name: 'CD',
        priority: 'Challenge'
      }
    }
  },
  products: {
    default: {
      styles: {
        DC: {
          name: 'DC',
          priority: 'Challenge'
        },
        D: {
          name: 'D',
          priority: 'Results',
          label: 'Dominance'
        },
        Di: {
          name: 'Di',
          priority: 'Action'
        },
        iD: {
          name: 'iD',
          priority: 'Action'
        },
        i: {
          name: 'i',
          priority: 'Enthusiasm',
          label: 'Influence'
        },
        iS: {
          name: 'iS',
          priority: 'Collaboration'
        },
        Si: {
          name: 'Si',
          priority: 'Collaboration'
        },
        S: {
          name: 'S',
          priority: 'Support',
          label: 'Steadiness'
        },
        SC: {
          name: 'SC',
          priority: 'Stability'
        },
        CS: {
          name: 'CS',
          priority: 'Stability'
        },
        C: {
          name: 'C',
          priority: 'Accuracy',
          label: 'Conscientiousness'
        },
        CD: {
          name: 'CD',
          priority: 'Challenge'
        }
      }
    },
    67: {
      styles: {
        DC: {
          name: 'DC',
          priority: 'Challenge'
        },
        D: {
          name: 'D',
          priority: 'Drive',
          label: 'Dominance'
        },
        Di: {
          name: 'Di',
          priority: 'Action'
        },
        iD: {
          name: 'iD',
          priority: 'Action'
        },
        i: {
          name: 'i',
          priority: 'Encouragement',
          label: 'Influence'
        },
        iS: {
          name: 'iS',
          priority: 'Collaboration'
        },
        Si: {
          name: 'Si',
          priority: 'Collaboration'
        },
        S: {
          name: 'S',
          priority: 'Support',
          label: 'Steadiness'
        },
        SC: {
          name: 'SC',
          priority: 'Reliability'
        },
        CS: {
          name: 'CS',
          priority: 'Reliability'
        },
        C: {
          name: 'C',
          priority: 'Objectivity',
          label: 'Conscientiousness'
        },
        CD: {
          name: 'CD',
          priority: 'Challenge'
        }
      }
    },
    71: {
      styles: {
        DC: {
          name: 'DC',
          priority: 'Challenge'
        },
        D: {
          name: 'D',
          priority: 'Results',
          label: 'Dominance'
        },
        Di: {
          name: 'Di',
          priority: 'Action'
        },
        iD: {
          name: 'iD',
          priority: 'Action'
        },
        i: {
          name: 'i',
          priority: 'Enthusiasm',
          label: 'Influence'
        },
        iS: {
          name: 'iS',
          priority: 'Collaboration'
        },
        Si: {
          name: 'Si',
          priority: 'Collaboration'
        },
        S: {
          name: 'S',
          priority: 'Support',
          label: 'Steadiness'
        },
        SC: {
          name: 'SC',
          priority: 'Stability'
        },
        CS: {
          name: 'CS',
          priority: 'Stability'
        },
        C: {
          name: 'C',
          priority: 'Accuracy',
          label: 'Conscientiousness'
        },
        CD: {
          name: 'CD',
          priority: 'Challenge'
        }
      }
    },
    107: {
      styles: {
        DC: {
          name: 'DC',
          priority: 'Justification'
        },
        D: {
          name: 'D',
          priority: 'Control',
          label: 'Dominance'
        },
        Di: {
          name: 'Di',
          priority: 'Assertion'
        },
        iD: {
          name: 'iD',
          priority: 'Assertion'
        },
        i: {
          name: 'i',
          priority: 'Expression',
          label: 'Influence'
        },
        iS: {
          name: 'iS',
          priority: 'Reassurance'
        },
        Si: {
          name: 'Si',
          priority: 'Reassurance'
        },
        S: {
          name: 'S',
          priority: 'Harmony',
          label: 'Steadiness'
        },
        SC: {
          name: 'SC',
          priority: 'Stability'
        },
        CS: {
          name: 'CS',
          priority: 'Stability'
        },
        C: {
          name: 'C',
          priority: 'Objectivity',
          label: 'Conscientiousness'
        },
        CD: {
          name: 'CD',
          priority: 'Justification'
        }
      }
    },
    111: {
      styles: {
        DC: {
          name: 'DC',
          priority: 'Resolute'
        },
        D: {
          name: 'D',
          priority: 'Self-assured',
          label: 'Dominance'
        },
        Di: {
          name: 'Di',
          priority: 'Dynamic'
        },
        iD: {
          name: 'iD',
          priority: 'Dynamic'
        },
        i: {
          name: 'i',
          priority: 'Outgoing',
          label: 'Influence'
        },
        iS: {
          name: 'iS',
          priority: 'Empathizing'
        },
        Si: {
          name: 'Si',
          priority: 'Empathizing'
        },
        S: {
          name: 'S',
          priority: 'Receptive',
          label: 'Steadiness'
        },
        SC: {
          name: 'SC',
          priority: 'Composed'
        },
        CS: {
          name: 'CS',
          priority: 'Composed'
        },
        C: {
          name: 'C',
          priority: 'Objective',
          label: 'Conscientiousness'
        },
        CD: {
          name: 'CD',
          priority: 'Resolute'
        }
      }
    }
  },
  flipCard: {
    front: 'Tell me more',
    back: 'Tell me less'
  },
  account: {
    new: 'New to @:brand.title?',
    create: 'Create an account',
    register: 'Register',
    login: 'Sign in',
    haveAccount: 'Already have an account?',
    signout: 'Log out',
    greeting: '@:ui.hi {username}',
    profilePicture: '{username}\'s profile picture',
    adminBadge: 'Administrator Badge',
    goToAdmin: 'Go to<br/>Admin',
    goToDashboard: 'Dashboard',
    createClass: 'Create Class',
    errDefault:
      'Your request could not be completed at this time. Please try again later.',
    err400: 'Your email or password is invalid.',
    err500: '@:account.errDefault',
    err409:
      'It looks like an account already exists with this email address. Please choose a different email.',
    err404: '@:account.errDefault',
    err401: '@:account.err400',
    registration: {
      title: 'Create your account'
    },
    welcome: 'Welcome to @:brand.title',
    signedIn: 'You\'re now signed in to @:brand.name on @:(brand.title).'
  },
  forms: {
    general: {
      invalid: 'This is a required entry',
      invalidSelection: 'Please make a selection',
      missingInfo:
        '1 field missing information | {count} fields missing information',
      placeholder: 'Type your answer here.',
      placeholderSelection: 'Select an option',
      review: 'Review my info',
      fix: 'Make changes'
    },
    fname: {
      name: 'FirstName',
      label: 'Enter your first name'
    },
    lname: {
      name: 'LastName',
      label: 'Enter your last name'
    },
    emailVerify: {
      name: 'Verify email',
      title: 'Sign in or sign up',
      validFeedback:
        'It looks like you already have an @:brand.name account. You can go ahead and sign in.',
      instructions:
        'Your email serves as your login for @:brand.name on @:(brand.title).',
      errNetwork: '@:account.errDefault',
      signIn: 'Go sign in'
    },
    email: {
      name: 'Email',
      label: 'Enter Email',
      placeholder: 'Enter your email address',
      invalid: "This doesn't look like a valid email address.",
      change: 'Change email'
    },
    confirmEmail: {
      name: 'Confirm email',
      placeholder: 'Enter your email address again',
      unmatched: "Your emails don't match. Try again.",
      unmatchedInvalid: "Your emails don't match, and this doesn't look like a valid email."
    },
    password: {
      name: 'Password',
      label: 'Enter password',
      placeholder: 'Enter your password',
      invalid: 'Password is required',
      invalidFeedback: 'This doesn\'t meet our password requirements.',
      instructions: `
        <b>Your password must be:</b>
        <ul><li>8-30 characters</li></ul>
        <b>And must include 3 of these:</b>
        <ul>
          <li>Uppercase character</li>
          <li>Lowercase character</li>
          <li>Number</li>
          <li>Special character: ~!@#$%^&*()-_+{}|[]\\:;"'?,./</li>
        </ul>
        <b>Don't use:</b>
        <ul>
          <li>Spaces</li>
          <li>Your email address</li>
          <li>Any special characters aside from those specified above</li>
          <li>Diacritical marks (e.g., é or ă)</li>
        </ul>
      `,
      forgot: '@:ui.forgot your password?',
      welcome: 'Welcome back! It looks like you already have an account.'
    },
    password2: {
      label: 'Enter your password again',
      placeholder: '@:forms.password.placeholder',
      invalidFeedback: "These passwords don't match"
    },
    language: {
      label: 'Preferred Language',
      languages: {
        'en-US': 'English',
        'da-DK': 'Danish',
        'ja-JP': 'Japanese'
      },
      warningAgileMgmt: 'Agile EQ&trade; and Management are only available in English.',
      warningAgile: 'Agile EQ&trade; is only available in English.',
      warningMgmt: 'Management is only available in English.',
      modal: {
        title: 'Change preferred language',
        body: 'Your assessment is still in progress. If you change your preferred language now, you’ll lose your work and need to restart the assessment. Do you still want to change your preferred language?',
        change: 'Change'
      }
    },
    location: {
      country: {
        name: 'Country',
        label: 'What\'s your primary work location country?'
      },
      state: {
        name: 'State/region/province',
        label: 'What\'s your primary work location state/region/province?'
      },
      city: {
        name: 'Primary city or office',
        label: 'What\'s your primary work location city? (Optional; but this will help people find you)'
      },
      name: 'Location',
      label: 'Enter your Office Location'
    },
    department: {
      name: 'Department',
      label: 'Choose the location you work in'
    },
    title: {
      name: 'Title',
      label: 'Enter your job title'
    },
    profileSharing: {
      name: 'Profile Sharing',
      label: 'Choose whether to share'
    },
    avatar: {
      name: 'Avatar',
      alt: 'Your avatar',
      label: 'Upload an image',
      link: 'Upload photo (optional)',
      clear: 'Click to clear file',
      or: 'Or',
      drag: 'drag image here',
      placeholder: 'Upload photo (optional) or drag image here',
      invalid: 'Image file format not detected. Please upload an image.',
      caption: 'Size limit: 2 megabytes'
    },
    review: {
      invalid: 'missing information'
    },
    demographics: {
      label: 'Thank you!',
      review: 'Click below to save and start the assessment.',
      submit: 'Take my assessment'
    },
    progress: {
      progress: 'Progress',
      completion: [
        "You're on your way!",
        'Getting there...',
        'About halfway there!',
        'Getting closer...',
        'Almost done...',
        "You're all done!"
      ]
    }
  },
  typeahead: {
    fetching: 'Fetching suggestions...',
    nomatches: 'No matches',
    type: 'Type for suggestions'
  },
  pathways: {
    disc: 'Your DiSC style',
    compare: 'Your colleagues',
    groups: 'Your groups',
    you: 'Workplace',
    management: 'Management',
    eq: 'Agile EQ',
    basics: 'DiSC&middot;ology'
  },
  appHeader: {
    pathways: {
      basics: {
        styles: {
          label: 'The 12 styles',
          description: 'Learn about the 12 distinct styles.'
        },
        history: {
          label: 'DiSC history',
          description:
            'DiSC has come a long way since William Moulton Marston proposed the idea of four basic DiSC styles back in 1928. Find out how Everything DiSC became what it is today.'
        },
        theory: {
          label: 'DiSC theory',
          description:
            'DiSC measures preferences and tendencies, not skill or ability. It’s a tool for dialogue, not diagnosis. Read more about the guiding principles behind DiSC.'
        },
        research: {
          label: 'DiSC research',
          description:
            'Wondering where all these insights come from? Or how we measure what we measure? Learn more about the research behind DiSC.'
        },
        overview: {
          label: 'The DiSC model',
          description: 'Get to know the model and the four basic styles.'
        }
      },
      you: {
        workplace: {
          label: 'What drives you',
          description:
            'When it comes to your work, have you ever noticed that there are certain things you tend to focus on? Or that you’re energized by some tasks but dread doing others? DiSC is a helpful tool for understanding those preferences and tendencies.'
        },
        'understand-your-relationships': {
          label: 'You and other styles',
          description:
            'There are probably some people you can relate to pretty easily, and others who—well, you’re not exactly sure where they stand. Learning what drives different people can shed some light on your relationships.'
        },
        'building-better-relationships': {
          label: 'Build better relationships',
          description:
            'Even though we all want good working relationships, that often means different things to different people. That’s why building better relationships starts with understanding where someone’s coming from and learning how to meet them where they are.'
        },
        download: {
          processing: '@:ui.processing',
          label: 'Download your Workplace report',
          error:
            'Uh-oh, something went wrong with your report link. Try refreshing the page, or see the "Need Help" section in the footer.'
        }
      },
      management: {
        'management-overview': {
          label: 'Management overview',
          description:
            'DiSC<sup>®</sup> is a valuable tool for managers. It gives you insight into your own management approach. And it helps you understand when and how to adapt your approach to the people you manage. Get a quick overview of how it works.'
        },
        'your-management-style': {
          label: 'Your management style',
          description:
            'There’s more than one way to be a good manager. DiSC sheds light on what <i>you</i> bring to this role—your priorities and preferences, the parts you enjoy, and the moments that stress you out.'
        },
        'direct-and-delegate': {
          label: 'Direct & delegate',
          description:
            'You’ve got your own approach to directing and delegating. But the people you manage may have different ideas about how they want to be supported and empowered to do their work. Learn how to bridge the gap.'
        },
        motivation: {
          label: 'Motivation',
          description:
            'You can’t motivate people. They have to motivate themselves. But as a manager, you <i>can</i> create an environment where it’s easy for people to find their own natural motivation. Discover how DiSC can help.'
        },
        'develop-talent': {
          label: 'Develop talent',
          description:
            'The managers we remember are the ones who help us identify and build on our strengths. Get style-based insights into how you can support your reports’ long-term professional growth.'
        },
        'manage-up': {
          label: 'Manage up',
          description:
            'A good working relationship with your manager is key to meeting the needs of your reports. Learn how to work more effectively with your manager.'
        }
      },
      eq: {
        'what-is-eq': {
          label: 'What is EQ?',
          description:
            'Emotional intelligence, or EQ, is about reading the emotional and interpersonal needs of a situation and responding accordingly. Learn more about EQ and the different forms it can take.'
        },
        'your-eq': {
          label: 'Your EQ strengths',
          description:
            'Did you know that there are different ways to be emotionally intelligent? And that we all have certain EQ strengths? Discover where your strengths lie.'
        },
        'recognizing-opportunities': {
          label: 'Beyond your comfort zone',
          description:
            "If you never stretch beyond what's comfortable, you're going to miss out on more effective ways to navigate certain situations. Learning about responses outside your comfort zone is the first step to growing your EQ and becoming more agile."
        },
        'becoming-agile': {
          label: 'Develop your EQ',
          description:
            'To develop your EQ, you need to reach for responses that may be uncomfortable or unfamiliar. Practice stretching to these responses with personalized tips and action steps.'
        }
      }
    }
  },
  people: {
    empty: 'None of your people are here yet.',
    filterEmpty: 'Uh-oh! No one fits your search criteria.',
    privateMode: 'Disabled when you’re not sharing',
    sort: {
      fullname: 'Name',
      style: '@.capitalize:ui.style',
      newest: 'Newest',
      department: '@.capitalize:ui.department',
      group: '@.capitalize:ui.group'
    },
    by: 'By',
    filtersSelected: '{count} filters selected',
    searchPeople: '@:ui.search @.lower:ui.people',
    byStyle: '@:people.by @:ui.style',
    byDepartment: '@:people.by @:ui.department',
    byGroup: '@:people.by @:ui.group',
    peopleCount: '{count} @.lower:ui.person | {count} @.lower:ui.people',
    filterCount: '@:ui.filter | @:ui.filter (1) | @:ui.filter ({count})',
    filters: {
      D: '@:brand.styles.D.name @:ui.styles',
      i: '@:brand.styles.i.name @:ui.styles',
      S: '@:brand.styles.S.name @:ui.styles',
      C: '@:brand.styles.C.name @:ui.styles'
    },
    privateMessage: {
      body: {
        default: 'Your colleagues can\'t see your DiSC<sup>®</sup> information, and you can\'t see theirs. Choose "Share with colleagues" to explore your similarities and differences and discover strategies for working together.',
        sample: "That means you can't explore how to work with individual colleagues, and they can't learn about working with you. Check out this sample colleague page to see what you're missing, or start sharing with colleagues."
      }
    }
  },
  peopleCard: {
    private: 'Turn on sharing to see their DiSC information.'
  },
  detail: {
    privateMessage: {
      body: "You can't see people in the {style} region unless you choose to share with colleagues. That will allow you to see your colleagues' DiSC styles, and for them to see yours."
    }
  },
  privateMessage: {
    title: "You're not sharing with colleagues.",
    sampleButton: 'See a sample colleague',
    shareButton: 'Share with colleagues',
    modal: {
      body: 'Are you ready to share with colleagues?',
      cancel: 'No, not yet',
      confirm: "Yes, let's go"
    },
    confirmation: "You're sharing with colleagues. To change this, go to your {0}.",
    privacyLink: 'privacy settings'
  },
  facilitatorCompare: 'You are viewing {0} ({1}) and {2}\'s ({3}) comparison report as {0}',
  maintenance: {
    toast: {
      heading: 'Maintenance underway!',
      body: "You may experience delays or other issues. Sorry for any inconvenience! We're working as fast as we can to fix things.",
      imageAlt: 'bulldozer icon',
      collapseButtonLabel: 'Collapse maintenance message',
      expandButtonLabel: 'Expand maintenance message'
    }
  },
  popupBlockedWarning: {
    title: 'Your browser is blocking popups',
    body: 'Please allow your browser to accept pop-ups to access Catalyst Administrator. You can do this by selecting the pop-up icon in the URL address bar or enabling it in your browser settings.',
    buttonText: 'Okay'
  },
  applications: {
    wp: 'WP',
    eq: 'EQ',
    mgmt: 'MGMT'
  },
  lookup: {
    enterName: 'Enter name',
    startOver: 'Start over',
    needHelp: 'Need help choosing? Look up or @.lower:(lookup.guess).',
    guess: "Guess a colleague's style",
    disabled: "Disabled when you're not sharing",
    results: {
      styleLookup: {
        title: '{learner} has',
        styles: {
          D: 'Select the D style to learn more about working with {learner} and others who have this style.',
          Di: '{learner} has a blend of D and i styles, but tends more toward D. Start with the D style to learn more.',
          iD: '{learner} has a blend of i and D styles, but tends more toward i. Start with the i style to learn more.',
          i: 'Select the i style to learn more about working with {learner} and others who have this style.',
          iS: '{learner} has a blend of i and S styles, but tends more toward i. Start with the i style to learn more.',
          Si: '{learner} has a blend of S and i styles, but tends more toward S. Start with the S style to learn more.',
          S: 'Select the S style to learn more about working with {learner} and others who have this style.',
          SC: '{learner} has a blend of S and C styles, but tends more toward S. Start with the S style to learn more.',
          CS: '{learner} has a blend of C and S styles, but tends more toward C. Start with the C style to learn more.',
          C: 'Select the C style to learn more about working with {learner} and others who have this style.',
          CD: '{learner} has a blend of C and D styles, but tends more toward C. Start with the C style to learn more.',
          DC: '{learner} has a blend of D and C styles, but tends more toward D. Start with the D style to learn more.'
        }
      },
      styleGuesser: {
        title: 'They likely have',
        result: 'Select the {style} style to learn more about working with this colleague and others who have this style.'
      }
    },
    guesser: {
      title: "Guess a colleague's style",
      question: 'Are they more...',
      question1: {
        answer1: 'Fast-paced & outspoken',
        answer2: 'Cautious & reflective'
      },
      question2: {
        answer1: 'Questioning & skeptical',
        answer2: 'Accepting & warm'
      }
    }
  },
  drawer: {
    toggleDrawer: 'toggle drawer'
  },
  overview: {
    discImageAltText: 'Everything DiSC map with vertical and horizontal axes'
  },
  discHistory: {
    altText: {
      wmMarston: 'William Moulton Marston',
      timeline1970: 'Personal Profile System graph',
      timeline1994: 'Personal Profile System 2800 Series graph',
      timeline2000: 'DiSC circumplex model',
      timeline2007: 'Everything DiSC Sales map',
      timeline2008: 'Everything DiSC Management map',
      timeline2009: 'Everything DiSC Workplace map',
      catalyst: ''
    }
  },
  whatIsEQ: {
    mindsetImageAltText: 'DiSC map with mindsets'
  },
  efforDialGraph: {
    effortLevel: {
      little: 'This mindset takes very little effort for you.',
      small: 'This mindset takes only a small amount of effort for you.',
      moderate: 'This mindset takes a moderate amount of effort for you.',
      good: 'This mindset takes a good deal of effort for you.',
      great: 'This mindset takes a great deal of effort for you.'
    }
  },
  difficultyBarImages: {
    altText: {
      difficulty1: 'difficulty rating 1 of 5',
      difficulty2: 'difficulty rating 2 of 5',
      difficulty3: 'difficulty rating 3 of 5',
      difficulty4: 'difficulty rating 4 of 5',
      difficulty5: 'difficulty rating 5 of 5'
    }
  },
  discResearch: {
    reliabilityTableHeader: {
      testRetest: 'Test-Retest',
      alpha: 'Alpha',
      week: '2 Week',
      title: 'Table 1. Scale Reliabilities'
    },
    validityTable: {
      title: 'Table 2. Scale Intercorrelations'
    },
    validityImageDescription: {
      figure: 'Figure 1',
      scaleDescription: 'MDS Map of a Two-Dimensional Solution',
      note: 'Note',
      stress: 'Stress = .01326; RSQ = .99825; N = 752.'
    }
  },
  expandDetailsButton: 'Expand',
  colapseDetailsButton: 'Collapse',
  expandButton: 'Expand details',
  colapseButton: 'Collapse details',
  graphContinua: {
    altText: 'from 0 [left-end label, e.g., {labelFrom}] to 100 [right-end label, e.g., {labelTo}] with placement indicated for you and your colleague'
  },
  skipLink: {
    text: 'Skip to main content'
  },
  donutGraphAlt: 'donut graph showing percentage of group members with each style'
}
