import Vue from 'vue'
import { GroupsService } from '../../services/groups'
import { filterGroupsResponse } from '../../util/groups'
import isDemoUser from '../../util/isDemoUser'

/**
 * @module Groups Module
 * @description Vuex module responsible for fetching and saving group data
 */

export const initialState = {
  statuses: {
    fetchGroups: {
      pending: false,
      status: 0,
      error: ''
    },
    fetchGroup: {
      pending: false,
      status: 0,
      error: ''
    },
    createGroup: {
      pending: false,
      status: 0,
      error: ''
    },
    editGroup: {
      pending: false,
      status: 0,
      error: ''
    },
    deleteGroup: {
      pending: false,
      status: 0,
      error: ''
    },
    personalizedFlags: {
      pending: false,
      status: 0,
      error: ''
    },
    leaveGroup: {
      pending: false,
      status: 0,
      error: ''
    }
  },
  groups: {
    Owner: [],
    AllMembers: []
  },
  demoExperience: {
    cachedMembers: [],
    hiddenGroups: []
  },
  editingGroup: {
    name: '',
    category1: 0,
    category2: 0,
    visibility: 'Owner',
    members: []
  },
  leavingGroup: {
    members: []
  },
  selectedGroupId: null
}

const state = Object.assign({}, initialState)

const getters = {
  pending: state => Object.entries(state.statuses).some(([_, request]) => request.pending),
  sortedGroups: state => Object.entries(state.groups).reduce(
    (sortedGroups, [visibility, groups]) => {
      return {
        ...sortedGroups,
        [visibility]: [...groups].sort((a, b) =>
          new Date(b.updated.replace('Z', '').concat('Z')) - new Date(a.updated.replace('Z', '').concat('Z'))
        ).filter(g => !state.demoExperience.hiddenGroups.includes(g.groupID))
      }
    }, {}
  ),
  /* BSA-1688 -> currently we have an inconsistencie in the values of update date coming from the API
    causing the ordenation of groups in the drawer to change when selecting a group to see details,
    since some values have an 'Z' by the end the solution for now is to make sure all the values
    have the same 'Z' at the end so the sorting will not be buggy
  */
  allGroups: (_, getters) => Object.values(getters.sortedGroups).reduce((acc, cur) => [...acc, ...cur], []),
  firstGroup: (_, getters) => getters.sortedGroups.Owner[0] ?? getters.sortedGroups.AllMembers[0] ?? null,
  selectedGroup: (state, getters) => getters.allGroups.find(g => g.groupID === state.selectedGroupId),
  getPersonalizationFlag: (_, getters) => (groupId, memberId, flagName) => {
    const member = getters.allGroups.find(
      (group) => group.groupID === groupId
    ).members.find(
      (member) => member.learner.accountID === memberId
    )

    if (member.personalizedFlags) {
      return member.personalizedFlags[flagName] ?? false
    }

    return false
  },
  getFirstGroup: (_, getters) => (memberId, groupIdExceptions = []) => {
    const groupsWithExceptions = getters.allGroups.filter(
      (group) => !groupIdExceptions.includes(group.groupID) && !getters.getPersonalizationFlag(group.groupID, memberId, 'hidden')
    )

    return groupsWithExceptions.find(
      (group) => (group.members.find((member) => member.learner.accountID === memberId).owner === true)
    ) ?? groupsWithExceptions.find(
      (group) => (group.members.find((member) => member.learner.accountID === memberId).owner === false)
    )
  }
}

const actions = {
  async fetchGroups ({ commit, state }, { memberID, type = 1, visibility = 'Owner', include = ['details', 'members'] }) {
    commit('fetchGroupsRequest')

    try {
      let groups = await GroupsService.getGroups({ memberID, type, visibility, include })

      groups = filterGroupsResponse(groups)

      if (isDemoUser()) {
        if (!state.demoExperience.cachedMembers.length) {
          commit('createDemoExperienceCachedMembers', groups[0].members)
        }

        state.groups[visibility].forEach(cachedGroup => {
          const matchingGroupIdx = groups.findIndex(g => g.groupID === cachedGroup.groupID)
          groups[matchingGroupIdx] = cachedGroup
        })
      }

      commit('fetchGroupsSuccess', { groups, visibility })
    } catch (e) {
      commit('fetchGroupsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  },

  async fetchGroup ({ commit, rootGetters, getters }, groupId) {
    commit('fetchGroupRequest')
    try {
      let group

      if (isDemoUser()) {
        const currentLearner = rootGetters['account/currentUserProfile']

        group = getters.allGroups.find(g => g.id === groupId)

        const demoUserIndex = group.members.findIndex(
          (member) => member.learner.accountID === currentLearner.accountID
        )

        if (demoUserIndex !== -1) {
          group.members[demoUserIndex].learner = currentLearner
        }
      } else {
        group = await GroupsService.getGroup(groupId)
      }

      commit('fetchGroupSuccess', group)
      return group
    } catch (e) {
      commit('fetchGroupError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
      return false
    }
  },

  async updateMemberPersonalizedFlags ({ commit, rootGetters, dispatch, state }, { groupId, groupMemberId, favorite, hidden }) {
    commit('personalizedFlagsRequest')

    try {
      const personalizedFlagRequest = { favorite, hidden }
      let updatedPersonalizedFlags

      if (isDemoUser()) {
        updatedPersonalizedFlags = personalizedFlagRequest
      } else {
        updatedPersonalizedFlags = await GroupsService.updatePersonalizedFlags({
          groupID: groupId,
          groupMemberID: groupMemberId,
          personalizedFlagsRequest: personalizedFlagRequest
        })
      }

      commit('personalizedFlagsSuccess', { groupId, groupMemberId, personalizedFlagRequest })
      return updatedPersonalizedFlags
    } catch (e) {
      commit('personalizedFlagsError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  },

  async selectGroup ({ commit, dispatch }, groupId) {
    if (groupId) {
      groupId = parseInt(groupId)
      if (state.selectedGroupId !== groupId) {
        const group = await dispatch('fetchGroup', groupId)
        if (group) {
          commit('selectGroup', groupId)
          return group
        }
        return false
      }
    } else {
      commit('selectGroup', null)
    }
  },

  async createGroup ({ commit, rootGetters, state }, ownerAccountID) {
    commit('createGroupRequest')

    try {
      let savedGroup

      if (isDemoUser()) {
        const nowDate = () => new Date()
        const groupId = () => Date.now()

        const { members, ...editingGroupWithoutMembers } = state.editingGroup

        const demoGroup = {
          id: groupId(),
          groupID: groupId(),
          organizationID: 0,
          created: nowDate().toISOString(),
          createdBy: ownerAccountID,
          updated: nowDate().toISOString(),
          updatedBy: ownerAccountID,
          context: 'Catalyst',
          type: 1,
          status: 'Active',
          ...editingGroupWithoutMembers,
          members: [{
            created: nowDate().toISOString(),
            createdBy: ownerAccountID,
            groupID: groupId(),
            groupMemberID: ownerAccountID,
            hidden: false,
            learner: { ...rootGetters['account/currentUserProfile'] },
            memberType: 'Member',
            owner: true,
            memberID: ownerAccountID,
            personalizedFlags: { favorite: false, hidden: false }
          }]
        }

        const otherMembers = state.editingGroup.members.map((member) => {
          const fullMember = state.demoExperience.cachedMembers.find(mem => mem.learner.accountID === member.learner.accountID)
          const fullLearnerProfile = fullMember.learner
          return {
            created: nowDate().toISOString(),
            createdBy: ownerAccountID,
            groupID: demoGroup.id,
            groupMemberID: fullLearnerProfile.accountID,
            hidden: false,
            learner: fullLearnerProfile,
            memberType: member.memberType ? member.memberType : 'Member',
            owner: false,
            memberID: fullLearnerProfile.accountID,
            personalizedFlags: { favorite: false, hidden: false }
          }
        })

        demoGroup.members = [...demoGroup.members, ...otherMembers]

        savedGroup = demoGroup
      } else {
        const groupRequest = {
          group: {
            ...state.editingGroup,
            context: 'Catalyst',
            type: 1,
            status: 'Active',
            members: [
              {
                memberType: 'Member',
                owner: true,
                memberID: ownerAccountID
              },
              ...state.editingGroup.members.map(member => {
                return {
                  memberType: member.memberType ? member.memberType : 'Member',
                  owner: member.owner ? member.owner : false,
                  memberID: member.learner.accountID
                }
              })
            ]
          }
        }

        savedGroup = await GroupsService.createGroup(groupRequest)
      }

      commit('createGroupSuccess', savedGroup)
      return savedGroup
    } catch (e) {
      commit('createGroupError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  },

  async editingGroup ({ commit, dispatch }, groupId) {
    commit('updateEditingGroup', initialState.editingGroup)

    if (groupId) {
      const group = await dispatch('fetchGroup', groupId)
      commit('updateEditingGroup', group)
    }
  },

  async editGroup ({ commit, state, rootGetters }) {
    commit('editGroupRequest')

    try {
      let savedGroup

      if (isDemoUser()) {
        const nowDate = () => new Date()

        const { members, ...editingGroupWithoutMembers } = state.editingGroup
        const groupBeingEdited = {
          updated: nowDate().toISOString(),
          updatedBy: state.editingGroup.createdBy,
          ...editingGroupWithoutMembers
        }

        const membersProfiles = members.map((member) => {
          const fullMember = state.demoExperience.cachedMembers.find(mem => mem.learner.accountID === member.learner.accountID)

          return {
            created: member.created ? member.created : nowDate().toISOString(),
            createdBy: member.createdBy ? member.createdBy : editingGroupWithoutMembers.createdBy,
            groupID: member.groupID ? member.groupID : groupBeingEdited.id,
            groupMemberID: member.groupMemberID ? member.groupMemberID : fullMember.learner.accountID,
            hidden: member.hidden ? member.hidden : false,
            learner: fullMember.learner,
            memberType: member.memberType ? member.memberType : 'Member',
            owner: member.owner ? member.owner : false,
            memberID: fullMember.learner.accountID,
            personalizedFlags: { favorite: false, hidden: false }
          }
        })

        groupBeingEdited.members = [...membersProfiles]

        savedGroup = groupBeingEdited
      } else {
        const groupRequest = {
          group: {
            ...state.editingGroup,
            context: 'Catalyst',
            type: 1,
            status: 'Active',
            members: [
              ...state.editingGroup.members.map(member => {
                return {
                  memberType: member.memberType ? member.memberType : 'Member',
                  owner: member.owner ? member.owner : false,
                  memberID: member.learner.accountID
                }
              })
            ]
          }
        }

        savedGroup = await GroupsService.updateGroup(groupRequest.group.groupID, groupRequest)
      }

      commit('editGroupSuccess', savedGroup)
      return savedGroup
    } catch (e) {
      commit('editGroupError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
    }
  },

  async leaveGroup ({ commit, rootGetters }, { group, groupMemberID, deleteGroupFromList }) {
    commit('leaveGroupRequest')

    try {
      if (!isDemoUser()) {
        await GroupsService.leaveGroup(group.groupID, groupMemberID)
      }

      commit('leaveGroupSuccess', { groupId: group.groupID, deleteGroupFromList: deleteGroupFromList ?? true })
      return true
    } catch (e) {
      commit('leaveGroupError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
      return false
    }
  },

  async deleteGroup ({ commit, rootGetters }, { group, deleteGroupFromList }) {
    commit('deleteGroupRequest')

    try {
      if (isDemoUser()) {
        // setTimeout() delays deleteGroup.status change, allowing the watcher to detect it and close the modal for DEMO users.
        await new Promise((resolve) => setTimeout(() => resolve(), 100))
      } else {
        await GroupsService.deleteGroup(group.groupID)
      }

      commit('deleteGroupSuccess', { groupId: group.groupID, deleteGroupFromList: deleteGroupFromList ?? true })
      return true
    } catch (e) {
      commit('deleteGroupError', {
        errorMessage: e.message,
        errorCode: e.errorCode
      })
      return false
    }
  },

  async removeGroupFromList ({ commit, rootGetters }, groupId) {
    commit('removeGroupFromList', groupId)

    if (isDemoUser()) {
      commit('demoExperienceHideGroup', groupId)
    }
  },

  editingGroupInput ({ commit }, updatedGroup) {
    commit('updateEditingGroup', updatedGroup)
  }
}

const mutations = {
  updateEditingGroup (state, updatedGroup) {
    state.editingGroup = updatedGroup

    // Clearing statuses in case you
    // encountered and error while
    // submitting and is adjusting
    // the data accordingly
    state.statuses.createGroup.status = 0
    state.statuses.createGroup.error = ''
    state.statuses.editGroup.status = 0
    state.statuses.editGroup.error = ''
  },
  selectGroup (state, groupId) {
    state.selectedGroupId = groupId
  },
  personalizedFlagsRequest (state) {
    state.statuses.personalizedFlags.pending = true
    state.statuses.personalizedFlags.status = 0
    state.statuses.personalizedFlags.error = ''
  },
  personalizedFlagsSuccess (state, { groupId, groupMemberId, personalizedFlagRequest }) {
    state.statuses.personalizedFlags.pending = false
    state.statuses.personalizedFlags.status = 200

    const group = state.groups.Owner.find(g => g.groupID === groupId) ||
      state.groups.AllMembers.find(g => g.groupID === groupId)
    if (group) {
      const member = group.members.find(m => m.groupMemberID === groupMemberId)
      if (member) {
        Vue.set(member, 'personalizedFlags', personalizedFlagRequest)
      }
    }
  },
  personalizedFlagsError (state, { errorMessage, errorCode }) {
    state.statuses.personalizedFlags.pending = false
    state.statuses.personalizedFlags.status = errorCode
    state.statuses.personalizedFlags.error = errorMessage
  },
  fetchGroupsRequest (state) {
    state.statuses.fetchGroups.pending = true
    state.statuses.fetchGroups.status = 0
    state.statuses.fetchGroups.error = ''
  },
  fetchGroupsSuccess (state, { groups, visibility }) {
    state.statuses.fetchGroups.pending = false
    state.statuses.fetchGroups.status = 200

    // First, filter out any groups that might be replaced by the incoming groups
    const existingGroupIDs = new Set(groups.map(g => g.groupID))
    state.groups[visibility] = state.groups[visibility].filter(g => !existingGroupIDs.has(g.groupID))

    state.groups[visibility].push(...groups)
  },
  fetchGroupsError (state, { errorMessage, errorCode }) {
    state.statuses.fetchGroups.pending = false
    state.statuses.fetchGroups.status = errorCode
    state.statuses.fetchGroups.error = errorMessage
  },
  fetchGroupRequest (state) {
    state.statuses.fetchGroup.pending = true
    state.statuses.fetchGroup.status = 0
    state.statuses.fetchGroup.error = ''
  },
  fetchGroupSuccess (state, group) {
    state.statuses.fetchGroup.pending = false
    state.statuses.fetchGroup.status = 200

    state.groups.Owner = state.groups.Owner.filter(g => g.groupID !== group.groupID)
    state.groups.AllMembers = state.groups.AllMembers.filter(g => g.groupID !== group.groupID)

    state.groups[group.visibility].push(group)
  },
  fetchGroupError (state, { errorMessage, errorCode }) {
    state.statuses.fetchGroup.pending = false
    state.statuses.fetchGroup.status = errorCode
    state.statuses.fetchGroup.error = errorMessage
  },
  createGroupRequest (state) {
    state.statuses.createGroup.pending = true
    state.statuses.createGroup.status = 0
    state.statuses.createGroup.error = ''
  },
  createGroupSuccess (state, group) {
    state.statuses.createGroup.pending = false
    state.statuses.createGroup.status = 200
    state.groups[group.visibility].unshift(group)
  },
  createGroupError (state, { errorMessage, errorCode }) {
    state.statuses.createGroup.pending = false
    state.statuses.createGroup.status = errorCode
    state.statuses.createGroup.error = errorMessage
  },
  editGroupRequest (state) {
    state.statuses.editGroup.pending = true
    state.statuses.editGroup.status = 0
    state.statuses.editGroup.error = ''
  },
  editGroupSuccess (state, group) {
    state.statuses.editGroup.pending = false
    state.statuses.editGroup.status = 200

    state.groups.Owner = state.groups.Owner.filter(g => g.groupID !== group.groupID)
    state.groups.AllMembers = state.groups.AllMembers.filter(g => g.groupID !== group.groupID)
    state.groups[group.visibility].unshift(group)
  },
  editGroupError (state, { errorMessage, errorCode }) {
    state.statuses.editGroup.pending = false
    state.statuses.editGroup.status = errorCode
    state.statuses.editGroup.error = errorMessage
  },
  deleteGroupRequest (state) {
    state.statuses.deleteGroup.pending = true
    state.statuses.deleteGroup.status = 0
    state.statuses.deleteGroup.error = ''
  },
  deleteGroupSuccess (state, { groupId, deleteGroupFromList }) {
    state.statuses.deleteGroup.pending = false
    state.statuses.deleteGroup.status = 200

    if (deleteGroupFromList) {
      state.groups.Owner = state.groups.Owner.filter(group => group.groupID !== groupId)
      state.groups.AllMembers = state.groups.AllMembers.filter(group => group.groupID !== groupId)
    }
  },
  deleteGroupError (state, { errorMessage, errorCode }) {
    state.statuses.deleteGroup.pending = false
    state.statuses.deleteGroup.status = errorCode
    state.statuses.deleteGroup.error = errorMessage
  },
  leaveGroupRequest (state) {
    state.statuses.leaveGroup.pending = true
    state.statuses.leaveGroup.status = 0
    state.statuses.leaveGroup.error = ''
  },
  leaveGroupSuccess (state, { groupId, deleteGroupFromList }) {
    state.statuses.leaveGroup.pending = false
    state.statuses.leaveGroup.status = 200

    if (deleteGroupFromList) {
      state.groups.Owner = state.groups.Owner.filter(group => group.groupID !== groupId)
      state.groups.AllMembers = state.groups.AllMembers.filter(group => group.groupID !== groupId)
    }
  },
  leaveGroupError (state, { errorMessage, errorCode }) {
    state.statuses.leaveGroup.pending = false
    state.statuses.leaveGroup.status = errorCode
    state.statuses.leaveGroup.error = errorMessage
  },
  removeGroupFromList (state, groupId) {
    state.groups.Owner = state.groups.Owner.filter(group => group.groupID !== groupId)
    state.groups.AllMembers = state.groups.AllMembers.filter(group => group.groupID !== groupId)
  },
  createDemoExperienceCachedMembers (state, members) {
    state.demoExperience.cachedMembers = members
  },
  demoExperienceHideGroup (state, groupId) {
    state.demoExperience.hiddenGroups.push(groupId)
  }
}

export const groups = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
