import Vue from 'vue'
import Router, { NavigationFailureType } from 'vue-router'
import { appLayouts } from './app/AppConstants'

const Login = () => import('./hocs/Login')
const Password = () => import('./hocs/Password')
const Assessment = () => import('./hocs/Assessment')
const ErrorGeneric = () => import('./views/errors/ErrorGeneric.vue')
const ErrorForbidden = () => import('./views/errors/ErrorForbidden.vue')
const ErrorUnavailable = () => import('./views/errors/ErrorUnavailable.vue')
const ErrorNotFound = () => import('./views/errors/ErrorNotFound.vue')
const ErrorSsoMismatch = () => import('./views/errors/ErrorSsoMismatch.vue')
const PrivacyPolicy = () => import('./views/PrivacyPolicy.vue')
const Terms = () => import('./views/Terms.vue')
const Cookies = () => import('./views/Cookies.vue')
const TranslatedLanding = () => import('./hocs/TranslatedLanding')
const HomeDeterminator = () => import('./hocs/HomeDeterminator')
const Register = () => import('./hocs/Register')
const CompleteProfile = () => import('./hocs/CompleteProfile')
const VerifyEmail = () => import('./hocs/VerifyEmail')
const ForgotPassword = () => import('./hocs/ForgotPassword')
const ResetPassword = () => import('./hocs/ResetPassword')
const AccessCode = () => import('./hocs/AccessCode')
const Demographics = () => import('./hocs/Demographics')
const Detail = () => import('./hocs/Detail')
const Landing = () => import('./hocs/Landing')
const Settings = () => import('./hocs/Settings')
const People = () => import('./hocs/People')
const Groups = () => import('./hocs/Groups')
const Compare = () => import('./hocs/Compare')
const Reports = () => import('./hocs/Reports')
const AdminAuthenticate = () => import('./hocs/AdminAuthenticate')
const DemoAuthenticate = () => import('./hocs/DemoAuthenticate')
const LaunchPlusModule = () => import('./hocs/LaunchPlusModule')
const YourManagementStyle = () => import('./hocs/YourManagementStyle')
const DevelopTalentLanding = () => import('./hocs/DevelopTalentLanding')
const DevelopTalentStyle = () => import('./hocs/DevelopTalentStyle')
const Motivation = () => import('./hocs/Motivation')
const ManageUpStyle = () => import('./hocs/ManageUpStyle')
const ManageUp = () => import('./hocs/ManageUp')
const MotivationStyle = () => import('./hocs/MotivationStyle')
const ManagementOverview = () => import('./hocs/ManagementOverview')
const YouAndOtherStyles = () => import('./hocs/YouAndOtherStyles')
const YourDiscStyle = () => import('./hocs/YourDiscStyle')
const WhatDrivesYou = () => import('./hocs/WhatDrivesYou')
const DirectDelegate = () => import('./hocs/DirectDelegate')
const DirectDelegateStyle = () => import('./hocs/DirectDelegateStyle')
const Overview = () => import('./hocs/Overview')
const BuildBetterRelationships = () => import('./hocs/BuildBetterRelationships')
const WhatIsEQ = () => import('./hocs/WhatIsEQ')
const DevelopYourEQ = () => import('./hocs/DevelopYourEQ')
const YourEQStrengths = () => import('./hocs/YourEQStrengths')
const BeyondYourComfortZone = () => import('./hocs/BeyondYourComfortZone')
const DiSCTheory = () => import('./hocs/DiSCTheory')
const DiSCResearch = () => import('./hocs/DiSCResearch')
const The12Styles = () => import('./hocs/The12Styles')
const DiSCHistory = () => import('./hocs/DiSCHistory')
const AccessibilityStatement = () => import('./views/AccessibilityStatement')
const GetAdviceLanding = () => import('./hocs/GetAdviceLanding')
const GetAdviceSearchAndCompare = () => import('./hocs/GetAdviceSearchAndCompare')
const Odessa = () => import('./hocs/Odessa')
const FacilitatorSession = () => import('./hocs/FacilitatorSession')
const LearnerSession = () => import('./hocs/LearnerSession')

/**
 * Redefining Router.push and Router.replace
 * Supressing only NavigationDuplicated
 *
 * Before vue-router v3.1, the .push and .replace functions were not
 * returning a promise and errors were handled by the global router
 * error handler, now they do and so we need to catch them.
 *
 * Since we're not using a single solution for all (router-link, prgramatically
 * calling router.push/replace) and we have cases where we use the good old A tag,
 * let's just catch and supress this specific error. Everything should work just
 * as it always worked.
 *
 * Source:
 * https://github.com/vuejs/vue-router/commit/d907a13#diff-1fdf421c05c1140f6d71444ea2b27638R153-R172
 *
 */
const defaultRouterPush = Router.prototype.push
const defaultRouterReplace = Router.prototype.replace

Router.prototype.push = function push (location, onComplete, onAbort) {
  if (onComplete || onAbort) return defaultRouterPush.call(this, location, onComplete, onAbort)
  return defaultRouterPush.call(this, location).catch((err) => {
    // eslint-disable-next-line eqeqeq
    if (err.name != 'NavigationDuplicated' && err.type !== NavigationFailureType.redirected) throw err
  })
}

Router.prototype.replace = function replace (location, onComplete, onAbort) {
  if (onComplete || onAbort) return defaultRouterReplace.call(this, location, onComplete, onAbort)
  return defaultRouterReplace.call(this, location).catch((err) => {
    // eslint-disable-next-line eqeqeq
    if (err.name != 'NavigationDuplicated' && err.type !== NavigationFailureType.redirected) throw err
  })
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeDeterminator
    },
    {
      path: '/PrivacyPolicy',
      alias: '/privacy-policy',
      name: 'privacyPolicy',
      component: PrivacyPolicy,
      meta: {
        public: true,
        layout: appLayouts.OPEN,
        pullPageText: true,
        context: 'respondent_site'
      }
    },
    {
      path: '/accessibility-statement',
      name: 'accessibilityStatement',
      component: AccessibilityStatement,
      meta: {
        public: true,
        layout: appLayouts.OPEN,
        pullPageText: true,
        context: 'accessibility-statement'
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
      meta: {
        public: true,
        layout: appLayouts.OPEN,
        pullPageText: true,
        context: 'terms'
      }
    },
    {
      path: '/cookies',
      name: 'cookies',
      component: Cookies,
      meta: {
        public: true,
        layout: appLayouts.OPEN,
        pullPageText: true,
        context: 'cookies'
      }
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorGeneric,
      meta: {
        public: true,
        layout: appLayouts.OPEN
      }
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: ErrorForbidden
    },
    {
      path: '/service-unavailable',
      name: 'serviceUnavailable',
      component: ErrorUnavailable,
      meta: {
        public: true
      }
    },
    {
      path: '/not-found',
      name: 'notFound',
      component: ErrorNotFound,
      meta: {
        public: true
      }
    },
    {
      path: '/error/sso',
      name: 'errorSsoMismatch',
      component: ErrorSsoMismatch,
      props: true,
      meta: {
        public: true,
        onlywhenLoggedOut: true,
        layout: appLayouts.OPEN
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true,
        layout: appLayouts.OPEN,
        browserWarning: true
      }
    },
    {
      path: '/login/profile',
      name: 'loginProfile',
      component: CompleteProfile,
      meta: {
        layout: appLayouts.OPEN
      }
    },
    {
      path: '/password',
      name: 'password',
      component: Password,
      meta: {
        public: true,
        layout: appLayouts.OPEN,
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/assessment/:page?/:panel?',
      name: 'assessment',
      component: Assessment,
      meta: {
        layout: appLayouts.NOSCROLL,
        hideCookieConsent: true
      }
    },
    {
      path: '/demographics/:panel?',
      name: 'demographics',
      component: Demographics,
      meta: {
        layout: appLayouts.NOSCROLL,
        hideCookieConsent: true,
        dynamicTitle: true
      }
    },
    {
      path: '/compare',
      name: 'people',
      component: People,
      props: route => ({
        query: route.query,
        showAlert: route.params.showAlert
      })
    },
    {
      path: '/compare/:peerLearnerId',
      name: 'compare',
      component: Compare,
      meta: {
        pullPageText: true,
        dynamicTitle: true,
        context: 'compare'
      }
    },
    {
      path: '/groups/:groupId?/:selectedTab?',
      name: 'groups',
      component: Groups,
      meta: {
        context: 'your-groups',
        pullPageText: true,
        dynamicTitle: true
      }
    },
    {
      path: '/get-advice',
      name: 'getAdvice',
      component: GetAdviceLanding,
      meta: {
        dynamicTitle: true
      }
    },
    {
      path: '/get-advice/:topic',
      name: 'getAdviceSearch',
      component: GetAdviceSearchAndCompare,
      meta: {
        dynamicTitle: true
      }
    },
    {
      path: '/get-advice/:topic/:peerLearnerId',
      name: 'getAdviceCompare',
      component: GetAdviceSearchAndCompare,
      meta: {
        dynamicTitle: true
      }
    },
    {
      path: '/disc',
      name: 'disc',
      component: YourDiscStyle,
      meta: {
        // page: () => '/disc',
        pullPageText: true,
        context: 'disc'
      }
    },
    {
      path: '/disc/basics/overview',
      name: 'overview',
      component: Overview,
      meta: {
        pullPageText: true,
        context: 'overview'
      }
    },
    {
      path: '/disc/basics/theory',
      name: 'theory',
      component: DiSCTheory,
      meta: {
        pullPageText: true,
        context: 'theory'
      }
    },
    {
      path: '/disc/basics/research',
      name: 'research',
      component: DiSCResearch,
      meta: {
        pullPageText: true,
        context: 'research'
      }
    },
    {
      path: '/disc/basics/styles',
      name: 'styles',
      component: The12Styles,
      meta: {
        pullPageText: true,
        context: 'styles'
      }
    },
    {
      path: '/disc/basics/history',
      name: 'history',
      component: DiSCHistory,
      meta: {
        pullPageText: true,
        context: 'history'
      }
    },
    {
      path: '/disc/you/workplace',
      alias: '/disc/you/what-drives-you',
      name: 'whatDrivesYou',
      component: WhatDrivesYou,
      meta: {
        context: 'workplace',
        pullPageText: true
      }
    },
    {
      path: '/disc/you/understand-your-relationships',
      alias: '/disc/you/you-and-other-styles',
      name: 'youAndOtherStyles',
      component: YouAndOtherStyles,
      meta: {
        context: 'understand-your-relationships',
        pullPageText: true
      }
    },
    {
      path: '/disc/you/building-better-relationships',
      alias: '/disc/you/build-better-relationships',
      name: 'buildBetterRelationships',
      component: BuildBetterRelationships,
      meta: {
        context: 'building-better-relationships',
        pullPageText: true
      }
    },
    {
      path: '/disc/management/management-overview',
      name: 'managementOverview',
      component: ManagementOverview,
      meta: {
        page: () => '/management-overview',
        pullPageText: true
      }
    },
    {
      path: '/disc/management/your-management-style',
      name: 'your-management-style',
      component: YourManagementStyle,
      meta: {
        page: () => '/your-management-style',
        pullPageText: true
      }
    },
    {
      path: '/disc/management/direct-and-delegate',
      name: 'direct-and-delegate',
      component: DirectDelegate,
      meta: {
        page: () => '/direct-and-delegate',
        pullPageText: true
      }
    },
    {
      path: '/disc/management/direct-and-delegate/:style',
      name: 'directDelegateStyle',
      component: DirectDelegateStyle,
      meta: {
        page: route => `/direct-and-delegate_${route.params.style}`,
        pullPageText: true
      }
    },
    {
      path: '/disc/management/motivation',
      name: 'motivation',
      component: Motivation,
      meta: {
        page: () => '/motivation',
        pullPageText: true
      }
    },
    {
      path: '/disc/management/develop-talent/',
      name: 'developTalentLanding',
      component: DevelopTalentLanding,
      meta: {
        page: () => '/develop-talent',
        pullPageText: true
      }
    },
    {
      path: '/disc/management/develop-talent/:style',
      name: 'developTalentStyle',
      component: DevelopTalentStyle,
      meta: {
        page: route => `/develop-talent_${route.params.style}`,
        pullPageText: true
      }
    },
    {
      path: '/disc/management/manage-up/:style',
      name: 'manageUpStyle',
      component: ManageUpStyle,
      meta: {
        page: route => `/manage-up_${route.params.style}`,
        pullPageText: true
      }
    },
    {
      path: '/disc/management/manage-up',
      name: 'manageUp',
      component: ManageUp,
      meta: {
        page: () => '/manage-up',
        pullPageText: true
      }
    },
    {
      path: '/disc/management/motivation/:style',
      name: 'motivationStyle',
      component: MotivationStyle,
      meta: {
        page: route => `/motivation_${route.params.style}`,
        pullPageText: true
      }
    },
    {
      path: '/disc/eq/what-is-eq',
      name: 'whatIsEQ',
      component: WhatIsEQ,
      meta: {
        page: () => '/what-is-eq',
        pullPageText: true
      }
    },
    {
      path: '/disc/eq/becoming-agile',
      alias: '/disc/eq/develop-your-eq',
      name: 'developYourEQ',
      component: DevelopYourEQ,
      meta: {
        page: () => '/becoming-agile',
        pullPageText: true
      }
    },
    {
      path: '/disc/eq/your-eq',
      name: 'yourEQ',
      component: YourEQStrengths,
      meta: {
        page: () => '/your-eq',
        pullPageText: true
      }
    },
    {
      path: '/disc/eq/recognizing-opportunities',
      alias: '/disc/eq/beyond-your-comfort-zone',
      name: 'beyoundYourComfortZone',
      component: BeyondYourComfortZone,
      meta: {
        page: () => '/recognizing-opportunities',
        pullPageText: true
      }
    },
    {
      path: '/disc/you',
      name: 'landingyou',
      component: TranslatedLanding,
      props: { nav: false, namespace: 'you' },
      meta: {
        context: 'you',
        pullPageText: true
      }
    },
    {
      path: '/disc/basics',
      name: 'landingbasics',
      component: TranslatedLanding,
      props: { nav: false, namespace: 'basics' },
      meta: {
        context: 'basics',
        pullPageText: true
      }
    },
    {
      path: '/disc/:pathway',
      name: 'landing',
      component: Landing,
      props: { nav: false },
      meta: {
        page: route => `/${route.params.pathway}`,
        pullPageText: true
      }
    },
    {
      path: '/disc/:pathway/:module/:style?',
      name: 'detail',
      component: Detail,
      meta: {
        page: route => {
          const module = `/${route.params.module}`
          const style = route.params.style ? `_${route.params.style}` : ''
          return module + style
        },
        pullPageText: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: VerifyEmail,
      meta: {
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true,
        layout: appLayouts.OPEN,
        browserWarning: true
      }
    },
    {
      path: '/register/profile',
      name: 'registerProfile',
      component: Register,
      meta: {
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true,
        layout: appLayouts.OPEN,
        browserWarning: true
      }
    },
    {
      path: '/request-reset',
      name: 'requestReset',
      component: ForgotPassword,
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
        layout: appLayouts.OPEN
      }
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPassword,
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
        layout: appLayouts.OPEN
      }
    },
    {
      path: '/access-code',
      name: 'accessCode',
      component: AccessCode,
      meta: {
        public: true,
        layout: appLayouts.OPEN,
        browserWarning: true
      }
    },
    {
      path: '/settings/:tab?',
      name: 'settings',
      component: Settings,
      meta: {
        layout: appLayouts.DEFAULT
      }
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      meta: {
        layout: appLayouts.DEFAULT
      }
    },
    {
      path: '/authenticate',
      name: 'adminAuthenticate',
      component: AdminAuthenticate
    },
    {
      path: '/launch-plus',
      name: 'launchPlusModule',
      component: LaunchPlusModule
    },
    {
      path: '/logout',
      name: 'logout'
    },
    {
      path: '/demo',
      name: 'demoExperience',
      component: DemoAuthenticate,
      meta: {
        public: true
      },
      beforeEnter: () => {
        window.location.href = '/v2/demo'
      }
    },
    {
      path: '/demo/style/:style',
      name: 'demoAuthenticate',
      component: DemoAuthenticate,
      meta: {
        public: true
      }
    },
    {
      // used only in main.js beforeEach to route auth SSO user
      path: '/sso/callback/:federation',
      name: 'ssoCallback'
    },
    {
      path: '/sso/:federation',
      name: 'ssoStartDirect',
      meta: {
        public: true,
        onlyWhenLoggedOut: true
      },
      beforeEnter: to => {
        window.location.href = `${window.env.PUBLIC_USERS_API}/api/v1/sso/${to.params.federation}`
      }
    },
    {
      path: '/sso/:federation/:username',
      name: 'ssoStart',
      meta: {
        public: true,
        onlyWhenLoggedOut: true
      },
      beforeEnter: to => {
        const state = encodeURIComponent(JSON.stringify({ redirect: '/', ...to.query, username: to.params.username }))
        window.location.href = `${window.env.PUBLIC_USERS_API}/api/v1/sso/${to.params.federation}?state=${state}`
      }
    },
    {
      path: '*',
      redirect: to => ({
        name: 'notFound',
        query: { retry: to.fullPath }
      }),
      meta: {
        public: true
      }
    },
    {
      path: '/odessa',
      name: 'odessa',
      component: Odessa,
      meta: {
        layout: appLayouts.DEFAULT
      }
    },
    {
      path: '/session',
      name: 'facilitatorSession',
      component: FacilitatorSession,
      meta: {
        hideFooter: true
      }
    },
    {
      path: '/session/:sessionId',
      name: 'lernerSession',
      component: LearnerSession,
      meta: {
        hideFooter: true
      }
    }
  ]
})

export default router

/**
 * text api page by route config option
 * @param { Object } route
 */
export const page = route => route.meta.page
  ? route.meta.page(route)
  : /^\/$/.test(route.path) ? `/${route.name}` : route.path
